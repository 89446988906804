import React from 'react';
import DefaultLayout from '../layouts';
import { getComponent } from '../utils';

const PageTemplate = ({ pageContext }) => {
  const {
    page: { title, content },
  } = pageContext;

  return (
    <DefaultLayout>
      <div className="base">
        <div className="container">
          <h1 className="h2 text-center">{title}</h1>
          {content.map(context => {
            const Component = getComponent(
              context.internal.type.replace('Contentful', '')
            );

            return <Component key={context.id} {...context} />;
          })}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default PageTemplate;
